import React, {
  useLayoutEffect,
  Fragment,
  FC,
  createRef,
  useState,
  useRef,
} from 'react';
import Slider, { Settings } from 'react-slick';
import Helmet from 'react-helmet';
import Masonry from 'react-masonry-component';

import UIStore from '@stores/UIStore';
import {
  GridContainer,
  GridItem,
  GridImageContainer,
  GridImage,
  GridText,
} from './GridComponents';
import { WorkProps } from 'src/templates/work';
import { isMobile } from '@config/mediaQueries';
import styled from 'styled-components';
import { getVideoSrcSized } from '@utils/assets';

const WorkCarousel: FC<{ onClick: () => void }> = props => {
  const sliderRef = createRef<Slider>();

  const gotoNext = () => {
    if (!sliderRef.current) return;

    sliderRef.current.slickNext();
  };

  return (
    <div
      onClick={() => {
        gotoNext();
        props.onClick();
      }}
      style={{ cursor: 'pointer' }}
    >
      <Slider ref={sliderRef} dots={false} arrows={false}>
        {props.children}
      </Slider>
    </div>
  );
};

const StyledVideo = styled.video({
  width: '100%',
});

const WorkVideo: FC<{
  src: string;
  autoPlay: boolean;
  onLoad?: () => void;
}> = props => {
  const videoRef = createRef<HTMLVideoElement>();

  if (!props.src) return null;

  return (
    <StyledVideo
      ref={videoRef}
      autoPlay={isMobile() ? true : props.autoPlay}
      muted
      loop
      playsInline
      onMouseOver={() => {
        if (isMobile()) return;
        if (!props.autoPlay) videoRef.current.play();
      }}
      onMouseLeave={() => {
        if (isMobile()) return;
        if (!props.autoPlay) {
          videoRef.current.pause();
          videoRef.current.currentTime = 0;
        }
      }}
      onLoad={props.onLoad}
    >
      {/* <source
        type="video/mp4"
        media="(min-width: 1800px)"
        srcSet={getVideoSrcSized(props.src, 'large')}
      />

      <source
        type="video/mp4"
        media="(min-width: 769px)"
        srcSet={getVideoSrcSized(props.src, 'medium')}
      /> */}

      <source type="video/mp4" src={getVideoSrcSized(props.src, 'large')} />
    </StyledVideo>
  );
};

export const Work = (props: WorkProps) => {
  const { workEntries } = props;
  const [opacity, setOpacity] = useState(1);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const initialImageLoadedCount = useRef(0);

  const entries = workEntries.filter(e => !e.hidden);

  useLayoutEffect(() => {
    setOpacity(isMobile() ? 1 : 0); // set opacity to 0 so we don't see the janky masonry
    UIStore.setupPage();
  }, []);

  const handleLayoutComplete = () => {
    setTimeout(() => setOpacity(1), isMobile() ? 2000 : 1000);
  };

  const handleInitialImageLoaded = () => {
    initialImageLoadedCount.current++;

    if (initialImageLoadedCount.current === entries.length) {
      setImagesLoaded(true);
    }
  };

  const forceImageLoad = () => {
    setImagesLoaded(true);
  };

  const ContainerEl = isMobile() ? Fragment : Masonry;

  return (
    <Fragment>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <div style={{ opacity }}>
        <GridContainer>
          <ContainerEl
            options={{ transitionDuration: 0, gutter: 30 }}
            onLayoutComplete={handleLayoutComplete}
            disableImagesLoaded={true}
          >
            {entries.map(entry => {
              return (
                <GridItem>
                  <WorkCarousel onClick={forceImageLoad}>
                    {entry.images.map((image, index) => {
                      if ('path' in image) {
                        return (
                          <GridImageContainer landscape={true}>
                            {index === 0 && (
                              <GridImage
                                image={image}
                                allowedSizes={['medium', 'small']}
                                onLoad={handleInitialImageLoaded}
                              />
                            )}
                            {index > 0 && imagesLoaded && (
                              <GridImage
                                image={image}
                                allowedSizes={['medium', 'small']}
                              />
                            )}
                          </GridImageContainer>
                        );
                      } else {
                        return (
                          <GridImageContainer landscape={true}>
                            <WorkVideo
                              src={image.video}
                              autoPlay={image.autoplay}
                              onLoad={
                                index === 0
                                  ? handleInitialImageLoaded
                                  : () => {}
                              }
                            />
                          </GridImageContainer>
                        );
                      }
                    })}
                  </WorkCarousel>
                  <GridText>{entry.name}</GridText>
                </GridItem>
              );
            })}
          </ContainerEl>
        </GridContainer>
      </div>
    </Fragment>
  );
};
