import React, { Fragment, useEffect } from 'react';

import { Meta } from '@components/Meta';
import { Footer } from '@components/Footer';
import { Layout } from '@components/Layout';
import { Work } from '@components/Work';

export type WorkProps = {
  workEntries: CmsWorkEntry[];
} & BasePageProps;

type Props = { pageContext: WorkProps };

export default ({ pageContext: { homepageFonts, workEntries } }: Props) => (
  <Fragment>
    <Meta title="Work" />

    <Layout homepageFonts={homepageFonts}>
      <Work workEntries={workEntries} />
    </Layout>
    <Footer />
  </Fragment>
);
